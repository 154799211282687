import React from "react";
import styles from "./Produtos02.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto02.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto02 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent image={CoverProduto} text={"Dossiê CMED"} />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Dossiê CMED" : "CMED dossier"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Realizamos o mapeamos de potenciais vantagens e riscos de
              enquadramento em cada categoria, com revisão sistemática da
              tecnologia e potenciais comparadores, quando indicado. Após traçar a
              estratégia, construímos as demais seções necessárias ao dossiê
              informativo de preços, para submissão à CMED.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We map potential advantages and risks of the framing
              for each CMED category, with a systematic review of the technology
              and potential comparators, when indicated. After outlining the
              strategy, we build the other sections necessary for the pricing
              dossier, for submission to CMED.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto02;
