import React from "react";
import styles from "./Produtos08.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto08.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto08 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Moderação de painel de especialistas"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Moderação de Painel de Especialistas" : "Expert Panel Moderation"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Preparamos e conduzimos painéis de especialistas, para obtenção de
              opiniões necessárias especialmente para a construção de dossiês de
              submissão à ANS e CONITEC, mas também para outras demandas. Nos
              painéis, utilizamos a metodologia Delphi, ou outras técnicas mais
              simples, a depender da situação.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We prepare and conduct panels of experts, to obtain necessary opinions
              especially for the construction of dossiers for submission to ANS and
              CONITEC, but also for other demands. In the panels, we use the Delphi
              methodology, or other simpler techniques, depending on the situation.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto08;
