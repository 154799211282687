import React from "react";
import styles from "./Produtos10.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto10.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto10 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Calculadora de custos de tratamento"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Calculadora de Custos de Tratamento" : "Treatment Cost Calculator"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Construímos calculadoras de custos de tratamento, usando metodologia
              desenvolvida in house, para comparação de diversas estratégias
              terapêuticas, em situações clínicas onde há multiplicidade de
              opções. Essas ferramentas são usadas especialmente para interação
              com gestores de operadoras ou de sistemas de saúde.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We build treatment cost calculators, using methodology developed in-house,
              to compare different therapeutic strategies, in clinical situations
              where there are multiple treatment options. These tools are used specially
              to interact with HMO or healthcare system managers.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto10;
