import React from "react";
import styles from "./Produtos11.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto11.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto11 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Análise crítica pré submissão"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Análises Crítica Pré Submssão" : "Pre-Submission Critical Analysis"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Alguns dos nossos clientes, após realizarem dossiês in house ou com
              terceiros, consideram valiosa a visão externa sobre pontos críticos
              do dossiê, como comparadores, qualidade da evidência, parâmetros de
              estudos de custo-efetividade e inputs de análise de impacto
              orçamentário.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              Some of our clients, after carrying out dossiers in-house or with third
              parties, consider that is it valuable to have an external view on critical
              points in the dossier, such as comparators, quality of evidence, parameters
              of cost-effectiveness studies and budget impact analysis inputs.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto11;
