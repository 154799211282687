import React from "react";
import styles from "./Produtos05.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto05.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto05 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Análise de impacto orçamentário"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Análise de Impacto Orçamentário" : "Budget Impact Analysis"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Conduzimos análises de impacto orçamentário, sob diversas
              perspectivas – seja de sistemas de saúde como um todo (SUS ou ANS),
              seja em escala menor, como de operadores de saúde ou de secretarias
              de saúde.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We conduct budget impact analyses, from different perspectives – whether
              of health systems as a whole (SUS or ANS), or on a smaller scale,
              such as HMO or municipal/state health secretaries.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto05;
