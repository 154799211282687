import React from "react";
import styles from "./Produtos06.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto06.png";
// import Grafico4 from "../../images/grafico4.png";
// import Grafico5 from "../../images/grafico5.jpg";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto06 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Estudos de revisão sistemática"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Estudos de Revisão Sistemática" : "Systematic Review Studies"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Realizamos estudos de revisão sistemática, seguindo as principais
              diretrizes nacionais (Ministério da Saúde) e internacionais
              (Cochrane Collaboration, PRISMA statement) para o desenvolvimento
              deste tipo de estudo. Após a revisão e localização dos estudos,
              conduzimos metanálises, se indicado, e avaliamos o risco de viés.
              Finalmente, aplicamos a metodologia GRADE, para graduar a qualidade
              da evidência.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We carry out systematic review studies, following the main national
              (Ministry of Health) and international (Cochrane Collaboration, PRISMA
              statement) guidelines for the development of this type of study.
              After reviewing and locating studies, we conducted meta-analyses,
              if indicated, and assess the risk of bias. Finally, we apply the GRADE
              methodology to grade the quality of the evidence.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto06;
