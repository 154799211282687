import React, { useState } from "react";
import LogoHeader from "../../images/Logo_Header.png";
import EngFlg from "../../images/united-kingdom_icon.png";
import BraFlg from "../../images/brazil_icon.png";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";

const Header = ({ page, onHandlePages, lang, onHandleLang }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onHandleLogoMobile = () => {
    return setIsMenuOpen(false);
  };

  const onHandleMenuMobile = () => {
    return setIsMenuOpen((oldValue) => !oldValue);
  };

  return (
    <header className={styles.header}>
      <main className={styles.headerContainer}>
        <NavLink
          to="/"
          onClick={() => {
            onHandleLogoMobile();
            onHandlePages("Home");
          }}
          className={page === "Home" ? styles.active : styles.navLink}
        >
          <img className={styles.logo} src={LogoHeader} alt="Logo Hemap" />
        </NavLink>

        <ul className={styles.navList}>
          <input type="checkbox" id={styles.checkbox_toggle} />
          <label
            for="checkbox_toggle"
            className={styles.hamburger}
            onClick={onHandleMenuMobile}
          >
            &#9776;
          </label>
          <nav className={styles.navBar}>
            <li className={styles.linkItem} id={styles.homeLink}>
              <NavLink
                to="/"
                onClick={() => onHandlePages("Home")}
                className={page === "Home" ? styles.active : styles.navLink}
              >
                Home
              </NavLink>
            </li>
            <li className={styles.linkItem} id={styles.sobreLink}>
              <NavLink
                to="/sobre"
                onClick={() => onHandlePages("Sobre")}
                className={page === "Sobre" ? styles.active : styles.navLink}
              >
                {lang === "BRA" ? "Sobre" : "About"}
              </NavLink>
            </li>
            <li className={styles.linkItem} id={styles.produtosLink}>
              <NavLink
                to="/produtos"
                onClick={() => onHandlePages("Produtos")}
                className={page === "Produtos" ? styles.active : styles.navLink}
              >
                {lang === "BRA" ? "Produtos" : "Products"}
              </NavLink>
            </li>
            <li className={styles.linkItem} id={styles.equipeLink}>
              <NavLink
                to="/equipe"
                onClick={() => onHandlePages("Equipe")}
                className={page === "Equipe" ? styles.active : styles.navLink}
              >
                {lang === "BRA" ? "Equipe" : "Team"}
              </NavLink>
            </li>
            <li className={styles.linkItem} id={styles.clientesLink}>
              <NavLink
                to="/clientes"
                onClick={() => onHandlePages("Clientes")}
                className={page === "Clientes" ? styles.active : styles.navLink}
              >
                {lang === "BRA" ? "Clientes" : "Clients"}
              </NavLink>
            </li>
            <li className={styles.linkItem} id={styles.contatoLink}>
              <NavLink
                to="/contato"
                onClick={() => onHandlePages("Contato")}
                className={page === "Contato" ? styles.active : styles.navLink}
              >
                {lang === "BRA" ? "Contato" : "Contact"}
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/blog"
                onClick={() => onHandlePages("Blog")}
                className={page === "Blog" ? styles.active : styles.navLink}
              >
                Blog
              </NavLink>
            </li> */}
          </nav>
          {isMenuOpen && (
            <nav className={styles.navBarMobile}>
              <li id={styles.linkItem}>
                <NavLink
                  to="/"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Home");
                  }}
                  className={page === "Home" ? styles.active : styles.navLink}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sobre"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Sobre");
                  }}
                  className={page === "Sobre" ? styles.active : styles.navLink}
                >
                  Sobre
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/produtos"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Produtos");
                  }}
                  className={
                    page === "Produtos" ? styles.active : styles.navLink
                  }
                >
                  Produtos
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/equipe"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Equipe");
                  }}
                  className={page === "Equipe" ? styles.active : styles.navLink}
                >
                  Equipe
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/clientes"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Clientes");
                  }}
                  className={
                    page === "Clientes" ? styles.active : styles.navLink
                  }
                >
                  Clientes
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contato"
                  onClick={() => {
                    onHandleMenuMobile();
                    onHandlePages("Contato");
                  }}
                  className={
                    page === "Contato" ? styles.active : styles.navLink
                  }
                >
                  Contato
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/blog"
                  onClick={() => onHandlePages("Blog")}
                  className={page === "Blog" ? styles.active : styles.navLink}
                >
                  Blog
                </NavLink>
              </li> */}
              <div className={styles.languagesMobile}>
                <button onClick={() => onHandleLang("BRA")} className={styles.btnFlag} >
                  <img className={styles.flagLang} src={BraFlg} />
                </button>
                <button onClick={() => onHandleLang("ENG")} className={styles.btnFlag} >
                  <img className={styles.flagLang} src={EngFlg} />
                </button>
              </div>
            </nav>
          )}
        </ul>
      </main>
      <div className={styles.languages}>
        <button onClick={() => onHandleLang("BRA")} className={styles.btnFlag} >
          <img className={styles.flagLang} src={BraFlg} />
        </button>
        <button onClick={() => onHandleLang("ENG")} className={styles.btnFlag} >
          <img className={styles.flagLang} src={EngFlg} />
        </button>
      </div>
    </header>
  );
};

export default Header;
