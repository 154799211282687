import AmgenLogo from "../images/logos/Amgen.svg";
import BmsLogo from "../images/logos/BMS.svg";
import SanofiLogo from "../images/logos/Sanofi.png";
import PfizerLogo from "../images/logos/pFizer.svg";
import AbbotLogo from "../images/logos/Abbott.png";
import AdiumLogo from "../images/logos/Adium.png";
import CSLViforLogo from "../images/logos/CSLVifor.png";
import LibbsLogo from "../images/logos/Libbs.png";
import NovoNordiskLogo from "../images/logos/NovoNordisk.png";
import RocheLogo from "../images/logos/Roche.png";
import GSKLogo from "../images/logos/GSK.png";
import BayerLogo from "../images/logos/Bayer.svg";

export const ClientesDB = [
    {
        company: "Amgen",
        image: AmgenLogo,
    },
    {
        company: "Pfizer",
        image: PfizerLogo,
    },
    {
        company: "BMS",
        image: BmsLogo,
    },
    {
        company: "Sanofi",
        image: SanofiLogo,
    },
    {
        company: "GSK",
        image: GSKLogo,
    },
    {
        company: "Abbot",
        image: AbbotLogo,
    },
    {
        company: "Libbs",
        image: LibbsLogo,
    },
    {
        company: "NovoNordisk",
        image: NovoNordiskLogo,
    },
    {
        company: "Roche",
        image: RocheLogo,
    },
    {
        company: "Bayer",
        image: BayerLogo,
    },
    {
        company: "CSLVifor",
        image: CSLViforLogo,
    },
    {
        company: "Adium",
        image: AdiumLogo,
    },
];
