import React, { useState, useEffect } from "react";
import styles from "./Cover.module.css";
import LogoCover from "../../images/Logo_Cover.png";

const CoverComponent = ({ image, showLogo = false, text = "" }) => {
  const [textWords, setTextWords] = useState([]);

  useEffect(() => {
    const textArr = text.split(" ");
    return setTextWords(textArr);
  }, [text]);

  return (
    <div className={styles.cover}>
      <img
        className={styles.backgroundImage}
        src={image}
        alt="Imagem de Capa"
      />
      {showLogo ? (
        <img
          className={styles.logo}
          src={LogoCover}
          alt="Logo Hemap Consulting"
        />
      ) : (
        <div className={styles.textDiv}>
          {textWords.map((elem, index) => {
            return (
              <div key={index} className={styles.text}>
                {elem}
              </div>
            );
          })}
          {/* <div className={styles.text}>NOSSOS</div>
          <div className={styles.text}>PRODUTOS</div> */}
        </div>
      )}
    </div>
  );
};

export default CoverComponent;
