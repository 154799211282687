import React from "react";
import styles from "./Produtos07.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto07.png";
// import Grafico6 from "../../images/grafico6.png";
// import Grafico7 from "../../images/grafico7.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto07 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Metanálises em rede"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Metanálises em Rede" : "Network Meta-Analyses"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Em situações com múltiplas tecnologias já incorporadas nos sistemas
              de saúde, uma realidade cada vez mais comum atualmente, empregamos
              técnica de metanálise em rede, comparando simultaneamente todas as
              tecnologias disponíveis.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              In situations with multiple technologies already incorporated into
              healthcare systems, an increasingly common reality today, we employ
              network meta-analysis technique, simultaneously comparing all
              available technologies.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto07;
