import React from "react";
import styles from "./CardProduto.module.css";
import { NavLink } from "react-router-dom";

const CardProduto = ({ title, image, titleColor, endereco, onHandlePages }) => {
  return (
    <div className={styles.card}>
      <NavLink
        to={`/produtos/${endereco}`}
        onClick={() => onHandlePages("Produtos")}
      >
        <div className={styles.cardImage}>
          <img className={styles.imgMotion} src={image} alt={title} />
        </div>
      </NavLink>
      <h3
        id={
          titleColor === "Dark" ? styles.titleCardDark : styles.titleCardLight
        }
      >
        {title}
      </h3>
    </div>
  );
};

export default CardProduto;
