import React from "react";
import styles from "./Produtos01.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto01.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto01 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Dossiê CONITEC e ANS"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Dossiê CONITEC e ANS" : "ANS and CONITEC dossiers"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Realizamos dossiês para pedido de incorporação de novas tecnologias
              nos sistemas de saúde público e suplementar. Nosso produto inclui
              todas as etapas necessárias ao dossiê, incluindo descrição da doença
              e da tecnologia, revisão sistemática (podendo incluir metanálise,
              inclusive em rede – network meta-analysis), estudo de
              custo-efetividade, análise de impacto orçamentário, e revisão de
              pareceres de agências de incorporação de tecnologias de outros
              países.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We create dossiers to request the incorporation of new technologies
              into public and supplementary healthcare systems. Our product includes
              all the necessary chapters for the dossier, including description of
              the disease and technology, systematic review (which may include
              meta-analysis, including network meta-analysis), cost-effectiveness
              study, budget impact analysis, and review of HTA agencies reports
              from other countries.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto01;
