import React from "react";
import styles from "./Colaborador04.module.css";
import ImgColaborador from "../../images/Equipe7.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador04 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgColaborador}
              alt="Dr. José Carlos Salomão Júnior"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Dr. José Carlos Salomão Júnior</h1>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Médico pela PUCRS com especialização em Ortopedia e
                  Traumatologia pelo Hospital Cristo Redentor, MBA em Gestão de
                  Negócios da Saúde pela Unisinos, Mestrando em Atenção Integral
                  da Saúde pela UNIJUI e UNICRUZ.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Responsável técnico do Laboratório de Inovação em Saúde na
                  Unimed Missões.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experiência na elaboração de estratégias de inovação,
                  implantação de estratégias de crescimento e adoção de novas
                  tecnologias em operadoras de saúde e hospitais.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • MD, graduated from PUCRS, with specialization in Orthopedics
                  and Traumatology from Hospital Cristo Redentor, MBA in Health
                  Business Management from Unisinos, Master's student in Comprehensive
                  Healthcare from UNIJUI and UNICRUZ.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Technical manager of the Health Innovation Laboratory at Unimed
                  Missões.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience in developing innovation strategies, implementing growth
                  strategies and adopting new technologies in HMOs and hospitals.
                </li>
              </ul>
            )}

          </div>
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador04;
