import React from "react";
import styles from "./Produtos09.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto09.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto09 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Escrita científica – abstracts e artigos"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Escrita Científica-Abstract e Artigos" : "Scientific Writing - Abstract and Articles"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Realizamos escrita científica, seja em formato breve (abstract para
              envio a congressos) ou completo (artigos para submissão a
              periódicos), de projetos de avaliação de tecnologias em saúde –
              revisão sistemática, análise de bases de dados e estudos econômicos.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We perform scientific writing, either as abstract (for submission to
              meetings) or full-text papers (articles for submission to journals),
              of health technology assessment projects – systematic review, database
              analysis and economic studies.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto09;
