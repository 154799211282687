import React, { useState } from "react";
import styles from "./App.module.css";
import AppRoutes from "./routes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
  const [page, setPage] = useState("Home");
  const [lang, setLang] = useState("BRA");

  const onHandlePages = (newPage) => {
    return setPage(newPage);
  };
  const onHandleLang = (newLang) => {
    return setLang(newLang);
  };

  return (
    <div className={styles.app}>
      <Header
        page={page}
        onHandlePages={onHandlePages}
        lang={lang}
        onHandleLang={onHandleLang}
      />
      <AppRoutes
        page={page}
        onHandlePages={onHandlePages}
        lang={lang}
        onHandleLang={onHandleLang}
      />
      <Footer onHandlePages={onHandlePages} lang={lang} />
    </div>
  );
}

export default App;
