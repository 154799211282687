import React from "react";
import styles from "./ButtonVoltar.module.css";

const ButtonVoltar = ({ text, icon, haveIcon = true, onClick, btnStyle }) => {
  return (
    <button
      className={btnStyle === "Dark" ? styles.buttonDark : styles.buttonLight}
      onClick={onClick}
    >
      <div id={styles.btnPos}>
        {haveIcon && (
          <img id={styles.btnIconImg} src={icon} alt="Icone Saiba Mais" />
        )}
        <span id={styles.btnText}>{text}</span>
      </div>
    </button>
  );
};

export default ButtonVoltar;
