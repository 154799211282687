import React from "react";
import styles from "./Colaborador05.module.css";
import ImgColaborador from "../../images/Equipe6.png";
import IconLattes from "../../images/iconLattes.png";
// import { NavLink } from "react-router-dom";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador05 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgColaborador}
              alt="Dra. Luciane Restelatto"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Dra. Luciane Restelatto</h1>
            <h3 id={styles.colaboradorCargo}>MD</h3>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Médica pela UFRGS, com especialização em Medicina Interna e
                  Terapia Intensiva pelo HCPA. Mestranda em Ciências Médicas pela
                  UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Médica intensivista no Hospital Moinhos de Vento e no HCPA.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Chefiou a Unidade de Terapia Intensiva do Hospital Divina
                  Providência entre os anos de 2017 e 2020.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • MD, graduated from UFRGS, with specialization in Internal Medicine
                  and Intensive Care from HCPA. Master's student in Medical Sciences
                  at UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Intensive care physician at Hospital Moinhos de Vento and HCPA.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Headed the Intensive Care Unit at Divina Providência Hospital
                  between 2017 and 2020.
                </li>
              </ul>
            )}

            <a
              href="http://lattes.cnpq.br/7698194244020141"
              target="_blank"
              rel="noreferrer"
            >
              <button id={styles.colaboradorButton}>
                <span>Lattes</span>
                <img src={IconLattes} alt="" />
              </button>
            </a>
          </div>
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador05;
