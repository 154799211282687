import React from "react";
import styles from "./CoverProduto.module.css";
// import LogoCoverProdutos from "../../images/Logo_CoverProdutos.png";

const CoverProdutoComponent = ({ image, text = "" }) => {
  return (
    <div className={styles.cover}>
      <img
        className={styles.backgroundImage}
        src={image}
        alt="Imagem de Capa"
      />
      {/* <div className={styles.logoTitleDiv}>
        <div className={styles.imgDiv}>
          <img
            className={styles.logo}
            src={LogoCoverProdutos}
            alt="Logo Hemap Consulting"
          />
        </div>

        <div className={styles.textDiv}>
          <div className={styles.text}>{text}</div>
        </div>
      </div> */}
    </div>
  );
};

export default CoverProdutoComponent;
