/* eslint-disable array-callback-return */
import React from "react";
import CoverComponent from "../../components/Cover/Cover";
import ImagemCover from "../../images/ImagemCover2.png";
import styles from "./Sobre.module.css";
import ImagemValores from "../../images/ImageValores.png";
import Button from "../../components/Button/Button";
import IconBtnSaibaMais from "../../images/iconBtn1.svg";
import CardColaborador from "../../components/CardColaborador/CardColaborador";
import {
  EquipeMedicaDB,
  EquipeMedicaImages,
  EquipeTecnicaDB,
  EquipeTecnicaImages,
} from "../../database/equipe";
import { useNavigate } from "react-router-dom";

const Sobre = ({ onHandlePages, lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = (newPage = null) => {
    onHandlePages(newPage);
    return navigate("/equipe");
  };

  return (
    <main className={styles.sobrePage}>
      <CoverComponent image={ImagemCover} showLogo />
      <section className={styles.sobreSection}>
        <h2 id={styles.sobreTitle}>HEMAP Consulting</h2>
        {lang === "BRA" ? (
          <>
            <p id={styles.textSobre}>
              A HEMAP Consulting é uma empresa especializada na área de avaliações
              de tecnologias em saúde (ATS) e acesso ao mercado (Market Access).
              Atuamos especialmente na temática de incorporação de novas
              tecnologias, tanto no sistema público como privado de saúde, com vasta
              experiência na construção de dossiês para submissão à CONITEC e ANS.
              Também atuamos na construção de dossiês de preço (CMED), assim como na
              produção de escrita científica.
            </p>
            <p id={styles.textSobre}>
              A empresa é liderada pelo Dr. Rodrigo Antonini Ribeiro, que possui
              mais de 15 anos de atuação na área de ATS e acesso ao mercado, com
              expertise na condução de revisões sistemáticas, metanálises, estudos
              de custo-efetividade e de impacto orçamentário.
            </p></>
        ) : (<>
          <p id={styles.textSobre}>
            HEMAP Consulting is a company specialized in the health technology
            assessments (HTA) and market access fields. We work especially with
            incorporation of new technologies, both in the public and private
            healthcare systems, with extensive experience in building dossiers
            for submission to the Brazilian public and private agencies (CONITEC
            and ANS). We also work on creating price dossiers (CMED), as well as
            producing scientific writing.
          </p>
          <p id={styles.textSobre}>
            The company is led by Dr. Rodrigo Antonini Ribeiro, who has over 15
            years of experience in the areas of HTA and market access, with
            expertise in conducting systematic reviews, meta-analyses,
            cost-effectiveness and budget impact studies.
          </p></>)}

      </section>

      <section className={styles.visaoSection}>
        <div className={styles.imageVisao}>
          <img id={styles.imageVisao} src={ImagemValores} alt="Imagem Visão" />
        </div>
        <div className={styles.visaoContent}>
          <h3 id={styles.visaoTitle}>{lang === "BRA" ? "Visão" : "Vision"}</h3>
          {lang === "BRA" ? (
            <p id={styles.visaoText}>
              Utilizar as melhores metodologias disponíveis para trazer respostas
              robustas e confiáveis no processo de precificação e incorporação de
              tecnologias em saúde.
            </p>)
            :
            (
              <p id={styles.visaoText}>
                Use the best available methodologies to provide robust and reliable
                answers in the process of pricing and adoption of health technologies.
              </p>
            )}
        </div>
      </section>

      <section className={styles.valoresSection}>
        <h3 id={styles.valoresTitle}>{lang === "BRA" ? "Valores" : "Core Values"}</h3>
        <div className={styles.valoresIconsDiv}>
          <div id={styles.valoresIcon}>
            <span id={styles.icons}>{lang === "BRA" ? "EXCELÊNCIA TÉCNICA" : "TECHNICAL EXCELLENCE"}</span>
          </div>
          <div id={styles.valoresIcon}>
            <span id={styles.icons}>{lang === "BRA" ? "ÉTICA" : "ETHICS"}</span>
          </div>
          <div id={styles.valoresIcon}>
            <span id={styles.icons}>{lang === "BRA" ? "ATUALIZAÇÃO CONSTANTE" : "CONSTANT UPDATE"}</span>
          </div>
          <div id={styles.valoresIcon}>
            <span id={styles.icons}>{lang === "BRA" ? "TRANSPARÊNCIA" : "TRANSPARENCY"}</span>
          </div>
          <div id={styles.valoresIcon}>
            <span id={styles.icons}>{lang === "BRA" ? "COMPROMETIMENTO COM O CLIENTE" : "COMMITMENT TO THE CUSTOMER"}</span>
          </div>
        </div>
        <div className={styles.valoresIconsDivMobile}>
          <li className={styles.listValores}>
            <div id={styles.valoresIcon} />
            <span id={styles.icons}>{lang === "BRA" ? "EXCELÊNCIA TÉCNICA" : "TECHNICAL EXCELLENCE"}</span>
          </li>
          <li className={styles.listValores}>
            <div id={styles.valoresIcon} />
            <span id={styles.icons}>{lang === "BRA" ? "ÉTICA" : "ETHICS"}</span>
          </li>
          <li className={styles.listValores}>
            <div id={styles.valoresIcon} />
            <span id={styles.icons}>{lang === "BRA" ? "ATUALIZAÇÃO CONSTANTE" : "CONSTANT UPDATE"}</span>
          </li>
          <li className={styles.listValores}>
            <div id={styles.valoresIcon} />
            <span id={styles.icons}>{lang === "BRA" ? "TRANSPARÊNCIA" : "TRANSPARENCY"}</span>
          </li>
          <li className={styles.listValores}>
            <div id={styles.valoresIcon} />
            <span id={styles.icons}>{lang === "BRA" ? "COMPROMETIMENTO COM O CLIENTE" : "COMMITMENT TO THE CUSTOMER"}</span>
          </li>
        </div>
      </section>

      <section className={styles.equipeSection}>
        <h3 id={styles.equipeTitle}>{lang === "BRA" ? "Nossa Equipe" : "Our Team"}</h3>
        <div className={styles.equipeDiv}>
          {EquipeMedicaDB.map((elem, index) => {
            if (index < 3) {
              return (
                <CardColaborador
                  key={index}
                  title={elem.name}
                  image={EquipeMedicaImages[index]}
                  endereco={elem.endereco}
                  onHandlePages={onHandlePages}
                />
              );
            }
          })}
          {EquipeTecnicaDB.map((elem, index) => {
            if (index < 2) {
              return (
                <CardColaborador
                  key={index}
                  title={elem.name}
                  image={EquipeTecnicaImages[index]}
                  endereco={elem.endereco}
                  onHandlePages={onHandlePages}
                />
              );
            }
          })}
        </div>
        <div id={styles.btnEquipeSection}>
          <Button
            text={lang === "BRA" ? "CONHEÇA MAIS" : "SEE MORE"}
            icon={IconBtnSaibaMais}
            onClick={() => handleOnClickEquipe("Equipe")}
            btnStyle={"Light"}
          />
        </div>
      </section>
    </main>
  );
};

export default Sobre;
