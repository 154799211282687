import React from "react";
import styles from "./Produtos03.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto03.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto03 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Pre-assessments de tecnologias em saúde"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Pre-Assessments de Tecnologias em Saúde" : "Pre-Assessments of Health Technologies"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Em cenários onde as empresas detentoras de novas tecnologias ainda
              não definiram o <i>go</i> ou <i>no go</i> para submissões de
              tecnologias à CONITEC ou à ANS. Nessas situações, a HEMAP realiza
              projetos de pre-assessment, mapeando pontos positivos e fraquezas
              acerca da evidência clínica da tecnologia em relação às alternativas
              já incorporadas aos sistemas de saúde. Adicionalmente, pode-se
              realizar estimativa preliminar de impacto orçamentário. Quando a
              empresa possui várias tecnologias, e está em fase de definição de
              priorização, atuamos da mesma forma, analisando todo o portfolio,
              indicando os produtos com maior chance de sucesso.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              There are scenarios where company owning new technologies have not
              yet defined the “go or no go” for technology submissions to CONITEC
              or ANS. In these situations, HEMAP carries out pre-assessment
              projects, mapping strengths and weaknesses regarding the clinical
              evidence of the technology in relation to alternatives already
              incorporated into healthcare systems. Additionally, a preliminary
              estimate of the budgetary impact can be made. When the company has
              several technologies, and is in the prioritization phase, we act
              in the same way, analyzing the entire portfolio, indicating the
              products with the greatest chance of success.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto03;
