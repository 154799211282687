/* eslint-disable array-callback-return */
import React, { useState } from "react";
import styles from "./Equipe.module.css";
import CoverCEO from "../../images/Equipe1.png";
import { NavLink } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import {
  EquipeMedicaDB,
  EquipeMedicaImages,
  EquipeTecnicaDB,
  EquipeTecnicaImages,
} from "../../database/equipe";
import CardColaborador from "../../components/CardColaborador/CardColaborador";

const Equipe = ({ onHandlePages, lang }) => {
  const [wichIsOpen, setWichIsOpen] = useState("");

  const onHandleBtnClick = (e, title) => {
    e.preventDefault();

    if (title === wichIsOpen) {
      return setWichIsOpen("");
    }

    return setWichIsOpen(title);
  };

  return (
    <main className={styles.mainEquipe}>
      <section className={styles.ceoSection}>
        <NavLink to={`/equipe/${EquipeMedicaDB[0].endereco}`}>
          <div id={styles.colaboradorImg}>
            <img id={styles.ceoImage} src={CoverCEO} alt="Foto CEO" />
          </div>
        </NavLink>

        <div className={styles.ceoContent}>
          <NavLink to={`/equipe/${EquipeMedicaDB[0].endereco}`}>
            <h1 id={styles.ceoTitle}>{lang === "BRA" ? "CEO e Diretor Técnico" : "CEO and Technical Director"}</h1>
          </NavLink>
          {lang === "BRA" ? (
            <p id={styles.ceoDescription}>
              O CEO e liderança técnica da empresa, Dr Rodrigo Antonini Ribeiro,
              possui vasta experiência na área de avaliação de tecnologias em
              saúde. Com sólida formação na área, incluindo mestrado e doutorado
              em epidemiologia, possui mais de 80 publicações em periódicos
              indexados, os quais possuem em conjunto mais de 2.000 citações.
              Participou diretamente da redação das diretrizes do Ministério da
              Saúde de Avaliação Econômica em Saúde (2ª edição) e de Análise de
              Impacto Orçamentário (1ª edição).
            </p>
          ) : (
            <p id={styles.ceoDescription}>
              The company's CEO and technical leadership, Dr Rodrigo Antonini Ribeiro,
              has extensive experience in the area of health technology assessment.
              With solid training in the area, including a master's and PhD degree
              in epidemiology, he has more than 80 publications in indexed journals,
              which together have more than 2,000 citations. He directly participated
              in the writing of the Ministry of Health guidelines for Health Economic
              Assessment (2nd edition) and Budgetary Impact Analysis (1st edition).
            </p>
          )}

          {lang === "BRA" ? (
            <div id={styles.togglesInfos}>
              <div id={styles.toggleItem1}>
                <Toggle
                  title={"INCORPORAÇÕES ANS"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "INCORPORAÇÕES ANS" && (
                  <div className={styles.infosItem1}>
                    <ul id={styles.rodrigoList}>
                      <li>• Lenalidomida (mieloma múltiplo recidivado)</li>
                      <li>• Lenalidomida (primeira linha em mieloma múltiplo)</li>
                      <li>
                        • Lenalidomida (manutenção pós-transplante, em mieloma
                        múltiplo)
                      </li>
                      <li>• Lenalidomida (síndrome mielodisplásica)</li>
                      <li>• Pomalidomida (mieloma múltiplo recidivado)</li>
                      <li>• Palbociclibe (câncer de mama)</li>
                      <li>• Encorafenibe associado ao binimetinibe (melanoma)</li>
                      <li>
                        • Encorafenibe associado ao cetuximabe (câncer de cólon)
                      </li>
                      <li>• Nintedanibe (câncer de pulmão)</li>
                      <li>• Lorlatinibe (câncer de pulmão)</li>
                    </ul>
                  </div>
                )}
              </div>
              <div id={styles.toggleItem2}>
                <Toggle
                  title={"INCORPORAÇÕES CONITEC"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "INCORPORAÇÕES CONITEC" && (
                  <div className={styles.infosItem2}>
                    <ul id={styles.rodrigoList}>
                      <li>• Ozurdex (edema macular diabético)</li>
                      <li>• Blinatumomabe (leucemia linfoblástica aguda)</li>
                      <li>• Carfilzomibe (mieloma múltiplo recidivado)</li>
                      <li>• Certolizumabe (artrite psoriásica)</li>
                    </ul>
                  </div>
                )}
              </div>
              <div id={styles.toggleItem3}>
                <Toggle
                  title={"SUBMISSÕES CMED"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "SUBMISSÕES CMED" && (
                  <div className={styles.infosItem3}>
                    <ul id={styles.rodrigoList}>
                      <li>• Mavacamten (cardiomiopatia hipertrófica)</li>
                      <li>• Luspatercepte (síndrome mielodisplásica)</li>
                      <li>• Yescarta (linfoma de grandes células B)</li>
                      <li>• Pomalidomida (mieloma múltiplo)</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div id={styles.togglesInfos}>
              <div id={styles.toggleItem1}>
                <Toggle
                  title={"ANS INCORPORATIONS"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "ANS INCORPORATIONS" && (
                  <div className={styles.infosItem1}>
                    <ul id={styles.rodrigoList}>
                      <li>• Lenalidomide (refractory/relapsed multiple myeloma)</li>
                      <li>• Lenalidomide (newly diagnosed multiple myeloma)</li>
                      <li>• Lenalidomide (post-transplant maintenance multiple myeloma)</li>
                      <li>• Lenalidomide (myelodysplastic syndrome)</li>
                      <li>• Pomalidomide (refractory/relapsed multiple myeloma)</li>
                      <li>• Palbociclib (breast cancer)</li>
                      <li>• Encorafenib associated with binimetinib (melanoma)</li>
                      <li>• Encorafenib associated with cetuximab (colon cancer)</li>
                      <li>• Nintedanib (lung cancer)</li>
                      <li>• Lorlatinib (lung cancer)</li>
                    </ul>
                  </div>
                )}
              </div>
              <div id={styles.toggleItem2}>
                <Toggle
                  title={"CONITEC INCORPORATIONS"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "CONITEC INCORPORATIONS" && (
                  <div className={styles.infosItem2}>
                    <ul id={styles.rodrigoList}>
                      <li>• Ozurdex (diabetic macular edema)</li>
                      <li>• Blinatumomab (acute lymphoblastic leukemia)</li>
                      <li>• Carfilzomib (refractory/relapsed multiple myeloma)</li>
                      <li>• Certolizumab (psoriatic arthritis)</li>
                    </ul>
                  </div>
                )}
              </div>
              <div id={styles.toggleItem3}>
                <Toggle
                  title={"CMED SUBMISSIONS"}
                  wichIsOpen={wichIsOpen}
                  onHandleBtnClick={onHandleBtnClick}
                />
                {wichIsOpen === "CMED SUBMISSIONS" && (
                  <div className={styles.infosItem3}>
                    <ul id={styles.rodrigoList}>
                      <li>• Mavacamten (hypertrophic cardiomyopathy)</li>
                      <li>• Luspatercept (myelodysplastic syndrome)</li>
                      <li>• Yescarta (large B-cell lymphoma)</li>
                      <li>• Pomalidomide (multiple myeloma)</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      <section className={styles.equipeSection}>
        <div className={styles.colaboradoresDiv}>
          <div id={styles.equipeCategoriaMedDiv}>
            <h2 id={styles.equipeCategoriaTitle}>{lang === "BRA" ? "Especialistas Médicos" : "Medical Specialists"}</h2>
            <div className={styles.medicosDiv1}>
              {EquipeMedicaDB.slice(1, 3).map((elem, index) => {
                if (elem.category === "Especialistas Médicos")
                  return (
                    <CardColaborador
                      key={index}
                      title={elem.name}
                      role={elem.title}
                      image={EquipeMedicaImages[index + 1]}
                      showRole
                      endereco={elem.endereco}
                      onHandlePages={onHandlePages}
                    />
                  );
              })}
            </div>
            <div className={styles.medicosDiv2}>
              {EquipeMedicaDB.slice(3, 5).map((elem, index) => {
                if (elem.category === "Especialistas Médicos")
                  return (
                    <CardColaborador
                      key={index}
                      title={elem.name}
                      role={elem.title}
                      image={EquipeMedicaImages[index + 3]}
                      showRole
                      endereco={elem.endereco}
                      onHandlePages={onHandlePages}
                    />
                  );
              })}
            </div>
          </div>

          <div id={styles.lineShape}></div>

          <div id={styles.equipeCategoriaTecDiv}>
            <h2 id={styles.equipeCategoriaTitle}>{lang === "BRA" ? "Consultores Técnicos" : "Technical Consultants"}</h2>
            <div className={styles.tecnicosDiv}>
              {EquipeTecnicaDB.map((elem, index) => {
                if (elem.category === "Consultores Técnicos")
                  return (
                    <CardColaborador
                      key={index}
                      title={elem.name}
                      role={elem.title}
                      image={EquipeTecnicaImages[index]}
                      endereco={elem.endereco}
                      onHandlePages={onHandlePages}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Equipe;
