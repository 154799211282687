import React, { useState, useEffect } from "react";
import CoverComponent from "../../components/Cover/Cover";
import ImagemCover from "../../images/ImagemCover1.png";
import styles from "./Home.module.css";
import Button from "../../components/Button/Button";
import IconBtnSaibaMais from "../../images/iconBtn1.svg";
import CardProduto from "../../components/CardProduto/CardProduto";
import CardImage1 from "../../images/CardProdutoHome1.png";
import CardImage2 from "../../images/CardProdutoHome2.png";
import CardImage3 from "../../images/CardProdutoHome3.png";
import logo1 from "../../images/logo1.png";
import logo2 from "../../images/logo2.png";
import logo3 from "../../images/logo3.png";
import logo4 from "../../images/logo4.png";
import { useNavigate } from "react-router-dom";
import Depoimentos from "../../components/Depoimentos/Depoimentos";
import { DepoimentosDB } from "../../database/depoimentos";

const Home = ({ page, onHandlePages, lang }) => {
  const navigate = useNavigate();

  const handleOnClickSobre = (newPage = null) => {
    onHandlePages(newPage);
    return navigate("/sobre");
  };

  const handleOnClickProdutos = (newPage = null) => {
    onHandlePages(newPage);
    return navigate("/produtos");
  };

  const handleOnClickClientes = (newPage = null) => {
    onHandlePages(newPage);
    return navigate("/clientes");
  };

  const [depoimentoAtual, setDepoimentoAtual] = useState(DepoimentosDB[0]);

  const handleClickNextDepoimento = () => {
    if (depoimentoAtual.id === DepoimentosDB.length) {
      setDepoimentoAtual(DepoimentosDB[0]);
    } else {
      setDepoimentoAtual(DepoimentosDB[depoimentoAtual.id]);
    }
  };

  const handleClickPreviousDepoimento = () => {
    if (depoimentoAtual.id === 1) {
      setDepoimentoAtual(DepoimentosDB[DepoimentosDB.length - 1]);
    } else {
      setDepoimentoAtual(DepoimentosDB[depoimentoAtual.id - 2]);
    }
  };

  useEffect(() => {
    if (page === "Home") {
      const randomDepoimento = Math.floor(Math.random() * (DepoimentosDB.length - 1));
      setDepoimentoAtual(DepoimentosDB[randomDepoimento]);
    }
  }, [page])

  return (
    <>
      {lang === "BRA" ?
        (<main className={styles.main}>
          <CoverComponent image={ImagemCover} showLogo />
          <section className={styles.sobreSection}>
            <h2 id={styles.sobreTitle}>Sobre a HEMAP Consulting</h2>
            <p id={styles.textSobre}>
              A HEMAP Consulting é uma empresa especializada na área de avaliações
              de tecnologias em saúde (ATS) e acesso ao mercado (Market Access).
              Atuamos especialmente na temática de incorporação de novas
              tecnologias, tanto no sistema público como privado de saúde, com vasta
              experiência na construção de dossiês para submissão à CONITEC e ANS.
              Também atuamos na construção de dossiês de preço (CMED), assim como na
              produção de escrita científica.
            </p>
            <div id={styles.btnSobreSection}>
              <Button
                text={"SAIBA MAIS"}
                icon={IconBtnSaibaMais}
                onClick={() => handleOnClickSobre("Sobre")}
                btnStyle={"Dark"}
              />
            </div>
          </section>
          <section className={styles.produtosSection}>
            <h3 id={styles.produtosTitle}>Nossos Produtos</h3>
            <div className={styles.produtosDiv}>
              <CardProduto
                title={"DOSSIÊ CONITEC E ANS"}
                image={CardImage1}
                titleColor={"Light"}
                endereco={"dossie-conitec-e-ans"}
                onHandlePages={onHandlePages}
              />
              <CardProduto
                title={"ESTUDOS DE CUSTO-EFETIVIDADE"}
                image={CardImage2}
                titleColor={"Light"}
                endereco={"estudo-de-custo-efetividade"}
                onHandlePages={onHandlePages}
              />
              <CardProduto
                title={"ANÁLISE DE IMPACTO ORÇAMENTÁRIO"}
                image={CardImage3}
                titleColor={"Light"}
                endereco={"analise-de-impacto-orcamentario"}
                onHandlePages={onHandlePages}
              />
            </div>
            <div id={styles.btnProdutosSection}>
              <Button
                text={"VEJA MAIS"}
                icon={IconBtnSaibaMais}
                onClick={() => handleOnClickProdutos("Produtos")}
                btnStyle={"Light"}
              />
            </div>
          </section>
          <Depoimentos
            depoimento={depoimentoAtual}
            handleClickNextDepoimento={handleClickNextDepoimento}
            handleClickPreviousDepoimento={handleClickPreviousDepoimento}
            lang={lang}
          />
          <section className={styles.logoSection}>
            <h3 id={styles.clientes}>Clientes</h3>
            <div className={styles.logosHorizontal}>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo1} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo4} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo2} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo3} alt="Logo" />
              </div>
            </div>

            <Button
              text={"VEJA MAIS"}
              icon={IconBtnSaibaMais}
              onClick={() => handleOnClickClientes("Clientes")}
              btnStyle={"Dark"}
            />
          </section>
        </main>)
        :
        (<main className={styles.main}>
          <CoverComponent image={ImagemCover} showLogo />
          <section className={styles.sobreSection}>
            <h2 id={styles.sobreTitle}>About HEMAP Consulting</h2>
            <p id={styles.textSobre}>
              HEMAP Consulting is a company specialized in the health technology assessments (HTA) and market access fields. We work especially with incorporation of new technologies, both in the public and private healthcare systems, with extensive experience in building dossiers for submission to the Brazilian public and private agencies (CONITEC and ANS). We also work on creating price dossiers (CMED), as well as producing scientific writing.
            </p>
            <div id={styles.btnSobreSection}>
              <Button
                text={"SEE MORE"}
                icon={IconBtnSaibaMais}
                onClick={() => handleOnClickSobre("Sobre")}
                btnStyle={"Dark"}
              />
            </div>
          </section>
          <section className={styles.produtosSection}>
            <h3 id={styles.produtosTitle}>Our Products</h3>
            <div className={styles.produtosDiv}>
              <CardProduto
                title={lang === "BRA" ? "DOSSIÊ CONITEC E ANS" : "ANS AND CONITEC DOSSIERS"}
                image={CardImage1}
                titleColor={"Light"}
                endereco={"dossie-conitec-e-ans"}
                onHandlePages={onHandlePages}
              />
              <CardProduto
                title={lang === "BRA" ? "ESTUDOS DE CUSTO-EFETIVIDADE" : "COST-EFFECTIVENESS STUDY"}
                image={CardImage2}
                titleColor={"Light"}
                endereco={"estudo-de-custo-efetividade"}
                onHandlePages={onHandlePages}
              />
              <CardProduto
                title={lang === "BRA" ? "ANÁLISE DE IMPACTO ORÇAMENTÁRIO" : "BUDGET IMPACT ANALYSIS"}
                image={CardImage3}
                titleColor={"Light"}
                endereco={"analise-de-impacto-orcamentario"}
                onHandlePages={onHandlePages}
              />
            </div>
            <div id={styles.btnProdutosSection}>
              <Button
                text={"SEE MORE"}
                icon={IconBtnSaibaMais}
                onClick={() => handleOnClickProdutos("Produtos")}
                btnStyle={"Light"}
              />
            </div>
          </section>
          <Depoimentos
            depoimento={depoimentoAtual}
            handleClickNextDepoimento={handleClickNextDepoimento}
            handleClickPreviousDepoimento={handleClickPreviousDepoimento}
            lang={lang}
          />
          <section className={styles.logoSection}>
            <h3 id={styles.clientes}>Clients</h3>
            <div className={styles.logosHorizontal}>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo1} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo4} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo2} alt="Logo" />
              </div>
              <div id={styles.logoDiv}>
                <img id={styles.logoItem} src={logo3} alt="Logo" />
              </div>
            </div>

            <Button
              text={"SEE MORE"}
              icon={IconBtnSaibaMais}
              onClick={() => handleOnClickClientes("Clientes")}
              btnStyle={"Dark"}
            />
          </section>
        </main>)}
    </>
  );
};

export default Home;
