import React from "react";
import styles from "./Colaborador07.module.css";
import ImgColaborador from "../../images/Equipe5.png";
import IconLattes from "../../images/iconLattes.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador07 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgColaborador}
              alt="Karlyse Claudino Belli"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Karlyse Claudino Belli</h1>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Fisioterapeuta e Analista de Dados, com mestrado e doutorado
                  em Ciências da Saúde pela UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Trabalha com consultorias de pesquisa para a área da saúde há
                  mais de 15 anos, tendo tido experiências ligadas ao Ministério
                  da Saúde, ANS, consultorias multinacionais envolvendo projetos
                  no Brasil, na América Latina e Globais.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Monitorou treinamentos do Ministério da Saúde sobre a
                  elaboração de PCDTs, DDTs e relatórios de incorporação de
                  tecnologias. Participou da elaboração de PCDTs junto ao
                  Ministério e de dossiês para incorporação das tecnologias para
                  diversas indústrias multinacionais.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Physiotherapist and data analyst, with master's and PhD degree
                  in Health Sciences from UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience with research consultancies for the health sector
                  for over 15 years, acting in projects from the pharmaceutical
                  industry focused on the Brazilian Ministry of Health and ANS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Monitored Ministry of Health training on the preparation of
                  clinical protocols and technology incorporation reports.
                </li>
              </ul>
            )}

            <a
              href="http://lattes.cnpq.br/0445915839669429"
              target="_blank"
              rel="noreferrer"
            >
              <button id={styles.colaboradorButton}>
                <span>Lattes</span>
                <img src={IconLattes} alt="" />
              </button>
            </a>
          </div>
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador07;
