import React from "react";
import styles from "./Depoimentos.module.css";
import IconSetaEsq from "../../images/IconSetaEsq.png";
import IconSetaDir from "../../images/IconSetaDir.png";

const Depoimentos = ({
  depoimento,
  handleClickNextDepoimento,
  handleClickPreviousDepoimento,
  lang
}) => {
  return (
    <section className={styles.depoimentoSection}>
      <button id={styles.btnSetas} onClick={handleClickPreviousDepoimento}>
        <img id={styles.seta} src={IconSetaEsq} alt="Icon Seta" />
      </button>
      <div className={styles.depoimentoTexts}>
        <h4 id={styles.depoimentoTitle}>{lang === "BRA" ? depoimento.title : depoimento.titleENG}</h4>
        <p id={styles.depoimento}>"{lang === "BRA" ? depoimento.text : depoimento.textENG}"</p>
        <div id={styles.pessoaCargoDiv}>
          <span id={styles.nomePessoa}>{depoimento.pessoaNome}</span>
          <span id={styles.cargo}>{lang === "BRA" ? depoimento.cargo : depoimento.cargoENG}</span>
        </div>
      </div>
      <button id={styles.btnSetas} onClick={handleClickNextDepoimento}>
        <img id={styles.seta} src={IconSetaDir} alt="Icon Seta" />
      </button>
    </section>
  );
};

export default Depoimentos;
