import React, { useState } from "react";
import styles from "./Contato.module.css";
import emailjs from "emailjs-com";
import Button from "../../components/Button/Button";
import IconBtnSaibaMais from "../../images/iconBtn1.svg";

const Contato = ({ lang }) => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      nome,
      email,
      mensagem,
    };

    emailjs
      .send(
        "service_so33z3d",
        "template_at9l85n",
        templateParams,
        "IhuoSlL7ckp3jcGZq"
      )
      .then(
        (response) => {
          console.log("Mensagem enviada com sucesso!", response);
          alert("Mensagem enviada com sucesso!");
        },
        (error) => {
          console.log("Falha ao enviar mensagem:", error);
          alert("Falha ao enviar mensagem. Tente novamente.");
        }
      );
  };

  return (
    <main className={styles.contatoPage}>
      <div className={styles.contatoDiv}>
        <form className={styles.formContato} onSubmit={handleSubmit}>
          <div className={styles.pessoaInfo}>
            <input
              id={styles.input}
              type="text"
              placeholder={lang === "BRA" ? "Nome" : "Name"}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              id={styles.input}
              type="email"
              placeholder={lang === "BRA" ? "Email" : "Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <textarea
            id={styles.textArea}
            placeholder={lang === "BRA" ? "Mensagem" : "Leave your message"}
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
        </form>
        <Button text={lang === "BRA" ? "ENVIAR" : "SEND"} icon={IconBtnSaibaMais} />
      </div>
    </main>
  );
};

export default Contato;
