import Equipe1 from "../images/Equipe1.png";
import Equipe2 from "../images/Equipe2.png";
import Equipe3 from "../images/Equipe3.png";
import Equipe4 from "../images/Equipe4.png";
import Equipe5 from "../images/Equipe5.png";
import Equipe7 from "../images/Equipe6.png";
import Equipe6 from "../images/Equipe7.png";

export const EquipeMedicaDB = [
    {
        name: "Dr. Rodrigo Ribeiro",
        title: "CEO e Diretor Técnico",
        category: "Especialistas Médicos",
        image: "Equipe1",
        endereco: "dr-rodrigo-antonini-ribeiro",
        email: "",
        phone: "",
        summary: "",
    },
    {
        name: "Dr. Eduardo Bertoli",
        title: "Cardiologia",
        category: "Especialistas Médicos",
        image: "Equipe2",
        endereco: "dr-eduardo-bertoli",
        email: "",
        phone: "",
        summary: "",
    },
    {
        name: "Dr. Leo Sekine",
        title: "Hematologia",
        category: "Especialistas Médicos",
        image: "Equipe3",
        endereco: "dr-leo-sekine",
        email: "",
        phone: "",
        summary: "",
    },
    {
        name: "Dr. José Carlos Salomão Júnior",
        title: "Ortopedia",
        category: "Especialistas Médicos",
        image: "Equipe7",
        endereco: "dr-jc-salomao",
        email: "",
        phone: "",
        summary: "",
    },
    {
        name: "Dra. Luciane Restelatto",
        title: "Terapia Intensiva",
        category: "Especialistas Médicos",
        image: "Equipe6",
        endereco: "dra-luciane-maria-restelatto",
        email: "",
        phone: "",
        summary: "",
    },
];

export const EquipeTecnicaDB = [
    {
        name: "Miriam Zago Marcolino",
        title: "",
        category: "Consultores Técnicos",
        image: "Equipe4",
        endereco: "miriam-zago-marcolino",
        email: "",
        phone: "",
        summary: "",
    },
    {
        name: "Karlyse Claudino Belli",
        title: "",
        category: "Consultores Técnicos",
        image: "Equipe5",
        endereco: "karlyse-claudino-belli",
        email: "",
        phone: "",
        summary: "",
    },
];

export const EquipeMedicaImages = [Equipe1, Equipe2, Equipe3, Equipe6, Equipe7];

export const EquipeTecnicaImages = [Equipe4, Equipe5];
