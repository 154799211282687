import React from "react";
import styles from "./CardColaborador.module.css";
import { NavLink } from "react-router-dom";

const CardColaborador = ({
  title,
  image,
  role,
  showRole = false,
  endereco,
  onHandlePages,
}) => {
  return (
    <div className={styles.card}>
      <NavLink
        to={`/equipe/${endereco}`}
        onClick={() => onHandlePages("Equipe")}
      >
        <div className={styles.cardImage}>
          <img className={styles.imgMotion} src={image} alt={title} />
        </div>
      </NavLink>
      {showRole && <span id={styles.roleCard}>{role}</span>}
      <h3 id={styles.titleCard}>{title}</h3>
    </div>
  );
};

export default CardColaborador;
