import React from "react";
import styles from "./Colaborador06.module.css";
import ImgColaborador from "../../images/Equipe4.png";
import IconLattes from "../../images/iconLattes.png";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador06 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgColaborador}
              alt="Miriam Zago Marcolino"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Miriam Zago Marcolino</h1>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Fisioterapeuta, Mestra em Ciências da Reabilitação pela
                  Universidade Federal de Ciências da Saúde de Porto Alegre
                  (UFCSPA), Doutoranda em Epidemiologia na Universidade Federal do
                  Rio Grande do Sul (UFRGS) e pesquisadora do Instituto Nacional
                  de Ciência e Tecnologia para ATS (INCT/CNPq).
                </li>
                <li id={styles.colaboradorDescription}>
                  • Possui experiência em Análise de Dados para pesquisa,
                  especialmente na análise de dados administrativos do SUS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Possui experiência com consultorias para elaboração de dossiês
                  de avaliação de tecnologias em saúde, incluindo a elaboração e
                  condução de revisões sistemáticas, metanálises e avaliações
                  econômicas em saúde há cerca de 8 anos.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Physiotherapist, Master in Rehabilitation Sciences from the
                  Federal University of Health Sciences of Porto Alegre (UFCSPA),
                  PhD student in Epidemiology at the Federal University of Rio
                  Grande do Sul (UFRGS) and researcher at the National Institute
                  of Science and Technology for ATS (INCT/ CNPq).
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience in data analysis in biomedical research, especially
                  in the analysis of the Brazilian SUS administrative data.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience with consultancies for the preparation of health
                  technology assessment dossiers, including the preparation and
                  conduction of systematic reviews, meta-analyses and economic
                  evaluations in health.
                </li>
              </ul>
            )}

            <a
              href="http://lattes.cnpq.br/0445915839669429"
              target="_blank"
              rel="noreferrer"
            >
              <button id={styles.colaboradorButton}>
                <span>Lattes</span>
                <img src={IconLattes} alt="" />
              </button>
            </a>
          </div>
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador06;
