import React from "react";
import styles from "./Toggle.module.css";
import BtnPlus from "../../images/iconBtnPlus.png";
import BtnMinus from "../../images/iconBtnMinus.png";

const Toggle = ({ title, wichIsOpen, onHandleBtnClick }) => {
  return (
    <div className={styles.btnDiv}>
      <button
        className={wichIsOpen !== title ? styles.btnIcon : styles.btnIconActive}
        onClick={(e) => onHandleBtnClick(e, title)}
      >
        <img
          id={wichIsOpen !== title ? styles.iconImg : styles.iconImgActive}
          src={wichIsOpen !== title ? BtnPlus : BtnMinus}
          alt="Button icon Plus"
        />
        <span id={styles.btnText}>{title}</span>
      </button>
    </div>
  );
};

export default Toggle;
