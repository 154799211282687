import React from "react";
import styles from "./Colaborador03.module.css";
import ImgColaborador from "../../images/Equipe3.png";
import IconLattes from "../../images/iconLattes.png";
// import { NavLink } from "react-router-dom";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador03 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img id={styles.imgId} src={ImgColaborador} alt="Dr. Leo Sekine" />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Dr. Leo Sekine</h1>
            <h3 id={styles.colaboradorCargo}>MD, MSc, PhD</h3>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Médico pela UFRGS, com especialização em Medicina Interna e
                  Hematologia e Hemoterapia pelo HCPA, e mestrado e doutorado em
                  Epidemiologia pelo PPG-EPI/UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Professor adjunto de Hematologia e Hemoterapia do Departamento
                  de Medicina Interna da Faculdade de Medicina da UFRGS. Professor
                  do Programa de Pós-Graduação em Ciências Médicas/UFRGS e do
                  Mestrado Profissional em Pesquisa Clínica/HCPA.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experiência na realização de revisões sistemáticas,
                  metanálise, ensaios clínicos randomizados e outros estudos
                  clínicos em hematologia, com diversas publicações nacionais e
                  internacionais no assunto.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  · MD, graduated from UFRGS, with specialization in Internal Medicine
                  and Hematology and Hemotherapy from HCPA, and master's and PhD
                  degree in Epidemiology from PPG-EPI/UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Professor of Hematology and Hemotherapy at the Department of
                  Internal Medicine at the Faculty of Medicine of UFRGS. Professor
                  of the Postgraduate Program in Medical Sciences/UFRGS and the
                  Professional Master's Degree in Clinical Research/HCPA.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience in carrying out systematic reviews, meta-analysis,
                  randomized clinical trials and other clinical studies in hematology,
                  with several national and international publications on the subject.
                </li>
              </ul>
            )}

            <a
              href="http://lattes.cnpq.br/8964177435510918"
              target="_blank"
              rel="noreferrer"
            >
              <button id={styles.colaboradorButton}>
                <span>Lattes</span>
                <img src={IconLattes} alt="" />
              </button>
            </a>
          </div>
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador03;
