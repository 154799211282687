import React from "react";
import styles from "./Colaborador02.module.css";
import ImgColaborador from "../../images/Equipe2.png";
import IconLattes from "../../images/iconLattes.png";
// import { NavLink } from "react-router-dom";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador02 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgColaborador}
              alt="Dr. Eduardo Gehling Bertoldi"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Dr. Eduardo Gehling Bertoldi</h1>
            <h3 id={styles.colaboradorCargo}>MD, MSc, PhD</h3>

            {lang === "BRA" ? (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • Médico pela UFPel, com especialização em Medicina Interna e
                  Cardiologia pelo HCPA. Possui mestrado e doutorado em
                  Cardiologia pelo Programa de Pós-Graduação em Cardiologia e
                  Ciências Cardiovasculares da UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Pesquisador do Instituto de Avaliação de Tecnologias em Saúde
                  (IATS) e professor do Programa de Pós-Graduação em Cardiologia
                  da UFRGS e da Faculdade de Medicina da UFPel.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experiência na realização de estudos de custo-efetividade e de
                  impacto orçamentário com diversas publicações nacionais e
                  internacionais no assunto.
                </li>
              </ul>
            ) : (
              <ul id={styles.colaboradorList}>
                <li id={styles.colaboradorDescription}>
                  • MD, graduated from UFPel, with specialization in Internal
                  Medicine and Cardiology from HCPA. He holds a master's and PhD
                  degree in Cardiology from the Postgraduate Program in Cardiology
                  and Cardiovascular Sciences at UFRGS.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Researcher at the Institute for Health Technology Assessment
                  (IATS) and professor at the Postgraduate Program in Cardiology
                  at UFRGS and the Faculty of Medicine at UFPel.
                </li>
                <li id={styles.colaboradorDescription}>
                  • Experience in carrying out cost-effectiveness and budget impact
                  studies with several national and international publications on
                  the subject.
                </li>
              </ul>
            )}

            <a
              href="http://lattes.cnpq.br/3127553513519653"
              target="_blank"
              rel="noreferrer"
            >
              <button id={styles.colaboradorButton}>
                <span>Lattes</span>
                <img src={IconLattes} alt="" />
              </button>
            </a>
          </div>
        </div>

        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
          icon={IconVoltar}
          onClick={() => handleOnClickEquipe()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Colaborador02;
