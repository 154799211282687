import React from "react";
import styles from "./Produtos.module.css";
import CoverComponent from "../../components/Cover/Cover";
import ImagemCover from "../../images/ImagemCover3.png";
import { ProdutosDB, ProdutoImages } from "../../database/produtos";
import CardProduto from "../../components/CardProduto/CardProduto";

const Produtos = ({ onHandlePages, lang }) => {
  return (
    <main className={styles.main}>
      <CoverComponent image={ImagemCover} text={lang === "BRA" ? "NOSSOS PRODUTOS" : "OUR PRODUCTS"} />
      <section className={styles.produtoSection}>
        <div id={styles.produtosDiv}>
          {ProdutosDB.map((elem, index) => {
            return (
              <CardProduto
                key={index}
                title={lang === "BRA" ? elem.title : elem.titleENG}
                image={ProdutoImages[index]}
                titleColor={"Dark"}
                endereco={elem.endereco}
                onHandlePages={onHandlePages}
              />
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default Produtos;
