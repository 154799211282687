export const DepoimentosDB = [
  {
    id: 1,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "Trabalho com o Rodrigo e sua equipe desde 2018 em diversos tipos de projetos voltados à Avaliação de Tecnologias em Saúde, a qualidade da entrega são sempre de altíssimo nível, com comprometimento e honestidade.",
    textENG: "I have been working with Rodrigo and his team since 2018 on different types of projects aimed at Health Technology Assessment. The quality of delivery is always of the highest level, with commitment and honesty.",
    pessoaNome: "TAMIÊ MARTINS",
    cargo: "HTA Partner",
    cargoENG: "HTA Partner at Roche"
  },
  {
    id: 2,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "A HEMAP é, sem dúvidas, a melhor empresa do segmento com a qual já trabalhei. Todos os projetos são liderados com transparência, seriedade e uma competência sem igual. Ter a HEMAP como parceira significa entrega de qualidade e sucesso garantido, não tem erro!",
    textENG: "HEMAP is, undoubtedly, the best company in the segment that I have ever worked with. All projects are led with transparency, seriousness and unparalleled competence. Having HEMAP as a partner means quality delivery and guaranteed success, you can't go wrong!",
    pessoaNome: "CAROLINE GRANATOWICZ",
    cargo: "Coordenadora de Acesso Estratégico e HEOR da Amgen ",
    cargoENG: "Strategic Access and HEOR Coordinator at Amgen"
  },
  {
    id: 3,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "Sem dúvida alguma, a HEMAP hoje é a consultoria mais especializada e com a melhor performance de resultados do mercado farmacêutico. As entregas sempre claras, com muito transparência, colaboram muito para que o processo com eles sempre seja muito fluido e sério. Quando quero acertar em projeto de acesso, não tenho dúvidas, escolho a HEMAP.",
    textENG: "Without a doubt, HEMAP is today the most specialized consultancy with the best results performance in the pharmaceutical market. Deliverables are always clear, with transparency, helping to ensure that the process with them is always very fluid and serious. When I want to get an access project right, I have no doubts, I choose HEMAP.",
    pessoaNome: "GUSTAVO GERALDO",
    cargo: "Gerente de Acesso Estratégico da Amgen",
    cargoENG: "Strategic Access Manager at Amgen"
  },
  {
    id: 4,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "Desde 2017 trabalho com a equipe da HEMAP em vários projetos de ATS com foco no acesso privado (ANS) e público (CONITEC). Neste período tivemos êxito com 4 importantes incorporações na área de onco-hematologia na ANS, desenvolvemos juntos vários assessments para novas tecnologias de pipeline e publicamos alguns artigos científicos e resumos. A equipe da HEMAP possui um conhecimento técnico ímpar e a qualidade das entregas é excepcional. Sou muito grato por poder continuar trabalhando com estes profissionais em projetos importantes atualmente sob minha responsabilidade.",
    textENG: "Since 2017, I have worked with the HEMAP team on several ATS projects focusing on private (ANS) and public (CONITEC) access. During this period, we were successful with 4 important incorporations in the area of onco-hematology at ANS. We also developed several assessments for new pipeline technologies and published some scientific articles and abstracts. The HEMAP team has unparalleled technical knowledge and the quality of deliveries is exceptional. I am very grateful to be able to continue working with these professionals on important projects currently under my responsibility.",
    pessoaNome: "FREDERICO MAGRO",
    cargo: "Diretor Associado de Acesso e Farmacoeconomia na Sanofi",
    cargoENG: "Associate Director of Market Access and Pharmacoeconomics at Sanofi"
  },
  {
    id: 5,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "A HEMAP tem sido parceira importante em projetos que envolvem avaliação de tecnologias em saúde. Os projetos são desenvolvidos em um ambiente de muita discussão técnica, profissionalismo e competência. Agradeço a parceria ao longo dos últimos anos.",
    textENG: "HEMAP has been an important partner in projects involving health technology assessment. Projects are developed in an environment with a lot of technical discussion, professionalism and competence. We are thankful for the partnership over the last few years.",
    pessoaNome: "RODRIGO ALEXANDRE",
    cargo: "Gerente de Farmacoeconomia na Pfizer",
    cargoENG: "Pharmacoeconomics Manager at Pfizer"
  },
  {
    id: 6,
    title: "O QUE NOSSOS CLIENTES DIZEM",
    titleENG: "SEE WHAT OUR CLIENTES SAY",
    text: "Trabalhar com a equipe da HEMAP é sempre um prazer, pois além dos prazos serem seguidos a risca, a entrega é de altíssima qualidade. Todos os pontos são debatidos e discutidos em  conjunto, com isso, garantimos alinhamento e rapidez na entrega,  antecipando qualquer questão que apareça na execução do projeto.",
    textENG: "Working with the HEMAP team is always a pleasure, as not only are deadlines strictly followed, but delivery is of the highest quality. All points are debated and discussed together, thereby ensuring alignment and speed of delivery, anticipating any issues that arise during the execution of the project.",
    pessoaNome: "NATÁLIA SANTONI",
    cargo: "Health Economics and Outcomes Research Manager na AbbVie",
    cargoENG: "Health Economics and Outcomes Research Manager at AbbVie"
  },
];
