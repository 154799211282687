import React from "react";
import styles from "./Colaborador01.module.css";
import ImgRodrigo from "../../images/Equipe1.png";
import IconLattes from "../../images/iconLattes.png";
// import { NavLink } from "react-router-dom";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Colaborador01 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickEquipe = () => {
    return navigate("/equipe");
  };

  return (
    <main className={styles.mainColaboradorCEO}>
      <section className={styles.colaboradorContent}>
        <div className={styles.colaboradorDivContent}>
          <div id={styles.colaboradorImg}>
            <img
              id={styles.imgId}
              src={ImgRodrigo}
              alt="Dr. Rodrigo Antonini Ribeiro"
            />
          </div>

          <div className={styles.colaboradorInfos}>
            <h1 id={styles.colaboradorTitle}>Dr. Rodrigo Antonini Ribeiro</h1>
            {lang === "BRA" ? (
              <p id={styles.colaboradorDescription}>
                O CEO e liderança técnica da empresa, Dr. Rodrigo Antonini
                Ribeiro, possui vasta experiência na área de avaliação de
                tecnologias em saúde. Com sólida formação na área, incluindo
                mestrado e doutorado em epidemiologia, possui mais de 80
                publicações em periódicos indexados, os quais possuem em conjunto
                mais de 2.000 citações. Participou diretamente da redação das
                diretrizes do Ministério da Saúde de Avaliação Econômica em Saúde
                (2ª edição) e de Análise de Impacto Orçamentário (1ª edição).
              </p>
            ) : (
              <p id={styles.colaboradorDescription}>
                The company's CEO and technical leadership, Dr Rodrigo Antonini
                Ribeiro, has extensive experience in the area of health technology
                assessment. With solid training in the area, including a master's
                and PhD degree in epidemiology, he has more than 80 publications
                in indexed journals, which together have more than 2,000 citations.
                He directly participated in the writing of the Ministry of Health
                guidelines for Health Economic Assessment (2nd edition) and Budgetary
                Impact Analysis (1st edition).
              </p>
            )}
            <div id={styles.colaboradorEmailPhone}>
              <span>E-mail: rodrigo.ribeiro@hemap.com.br</span>
              {/* <span>Telefone: (51) 99999-9999</span> */}
            </div>
            <div className={styles.botoesContent}>
              <a
                href="http://lattes.cnpq.br/0227030470601631"
                target="_blank"
                rel="noreferrer"
              >
                <button id={styles.colaboradorButton}>
                  <span>Lattes</span>
                  <img src={IconLattes} alt="" />
                </button>
              </a>
              <a
                href="https://www.linkedin.com/in/rodrigo-antonini-ribeiro-45a59718/"
                target="_blank"
                rel="noreferrer"
              >
                <button id={styles.colaboradorButton}>
                  <span>Linkedin</span>
                  <img src={IconLattes} alt="" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.colaboradorContentTexts}>
        <div className={styles.colaboradorContentTextsDiv}>
          {lang === "BRA" ? (
            <p id={styles.colaboradorText}>
              Na sua atuação como consultor em projetos de avaliação de novas
              tecnologias, durante vários anos à frente da HTAnalyze, se envolveu
              diretamente em mais de 30 submissões à ANS, CONITEC e CMED. Entre os
              casos de sucesso, são destaque:
            </p>
          ) : (
            <p id={styles.colaboradorText}>
              In his role as a consultant in new technology evaluation projects,
              for several years leading HTAnalyze and more recently HEMAP, he was
              directly involved in more than 30 submissions to ANS, CONITEC and
              CMED. Among the success cases, the following stand out:
            </p>
          )}


          <div id={styles.colaboradorListsDivs}>
            <div>
              <h3 id={styles.colaboradorTitleText}>
                {lang === "BRA" ? "Sistema privado de saúde/ANS:" : "Private healthcare system/ANS:"}
              </h3>
              {lang === "BRA" ? (
                <ul id={styles.colaboradorList}>
                  <li>
                    • Lenalidomida (mieloma múltiplo recidivado)
                  </li>
                  <li>• Lenalidomida (primeira linha em mieloma múltiplo)</li>
                  <li>
                    • Lenalidomida (manutenção pós-transplante, em mieloma
                    múltiplo)
                  </li>
                  <li>• Lenalidomida (síndrome mielodisplásica)</li>
                  <li>• Pomalidomida (mieloma múltiplo recidivado)</li>
                  <li>• Palbociclibe (câncer de mama)</li>
                  <li>• Encorafenibe associado ao binimetinibe (melanoma)</li>
                  <li>
                    • Encorafenibe associado ao cetuximabe (câncer de cólon)
                  </li>
                  <li>• Nintedanibe (câncer de pulmão)</li>
                  <li>• Lorlatinibe (câncer de pulmão)</li>
                </ul>
              ) : (
                <ul id={styles.colaboradorList}>
                  <li>• Lenalidomide (refractory/relapsed multiple myeloma)</li>
                  <li>• Lenalidomide (newly diagnosed multiple myeloma)</li>
                  <li>• Lenalidomide (post-transplant maintenance multiple myeloma)</li>
                  <li>• Lenalidomide (myelodysplastic syndrome)</li>
                  <li>• Pomalidomide (refractory/relapsed multiple myeloma)</li>
                  <li>• Palbociclib (breast cancer)</li>
                  <li>• Encorafenib associated with binimetinib (melanoma)</li>
                  <li>• Encorafenib associated with cetuximab (colon cancer)</li>
                  <li>• Nintedanib (lung cancer)</li>
                  <li>• Lorlatinib (lung cancer)</li>
                </ul>
              )}
            </div>

            <div>
              <h3 id={styles.colaboradorTitleText}>
                {lang === "BRA" ? "Sistema público de saúde/CONITEC:" : "Public healthcare system/CONITEC:"}
              </h3>
              {lang === "BRA" ? (
                <ul id={styles.colaboradorList}>
                  <li>• Ozurdex (edema macular diabético)</li>
                  <li>• Blinatumomabe (leucemia linfoblástica aguda)</li>
                  <li>• Carfilzomibe (mieloma múltiplo recidivado)</li>
                  <li>• Certolizumabe (artrite psoriásica)</li>
                </ul>
              ) : (
                <ul id={styles.colaboradorList}>
                  <li>• Ozurdex (diabetic macular edema)</li>
                  <li>• Blinatumomab (acute lymphoblastic leukemia)</li>
                  <li>• Carfilzomib (refractory/relapsed multiple myeloma)</li>
                  <li>• Certolizumab (psoriatic arthritis)</li>
                </ul>
              )}
              <h3 id={styles.colaboradorTitleText}>{lang === "BRA" ? "Precificação - CMED:" : "Pricing - CMED:"}</h3>
              {lang === "BRA" ? (
                <ul id={styles.colaboradorList}>
                  <li>• Mavacamten (cardiomiopatia hipertrófica)</li>
                  <li>• Luspatercepte (síndrome mielodisplásica)</li>
                  <li>• Yescarta (linfoma de grandes células B)</li>
                  <li>• Pomalidomida (mieloma múltiplo)</li>
                </ul>
              ) : (
                <ul id={styles.colaboradorList}>
                  <li>• Mavacamten (hypertrophic cardiomyopathy)</li>
                  <li>• Luspatercept (myelodysplastic syndrome)</li>
                  <li>• Yescarta (large B-cell lymphoma)</li>
                  <li>• Pomalidomide (multiple myeloma)</li>
                </ul>
              )}
            </div>
          </div>

          {lang === "BRA" ? (
            <p id={styles.colaboradorText2}>
              A longa trajetória de sucesso em dossiês de incorporação e de
              precificação contaram, seguidamente, com a participação de médicos
              especialistas de renome na área, além de profissionais com profundo
              conhecimento de metodologia científica aplicada à avaliação de
              tecnologias em saúde.
            </p>
          ) : (
            <p id={styles.colaboradorText2}>
              The long history of success in incorporation and pricing dossiers
              has included the participation of renowned medical specialists in
              the field, as well as professionals with in-depth knowledge of scientific
              methodology applied to the assessment of health technologies.
            </p>
          )}

          <ButtonVoltar
            text={lang === "BRA" ? "VOLTAR PARA EQUIPE" : "BACK TO TEAM"}
            icon={IconVoltar}
            onClick={() => handleOnClickEquipe()}
            btnStyle={"Light"}
          />
        </div>
      </section>
    </main>
  );
};

export default Colaborador01;
