import Produto1 from "../images/Produto1.png";
import Produto2 from "../images/Produto2.png";
import Produto3 from "../images/Produto3.png";
import Produto4 from "../images/Produto4.png";
import Produto5 from "../images/Produto5.png";
import Produto6 from "../images/Produto6.png";
import Produto7 from "../images/Produto7.png";
import Produto8 from "../images/Produto8.png";
import Produto9 from "../images/Produto9.png";
import Produto10 from "../images/Produto10.png";
import Produto11 from "../images/Produto11.png";

export const ProdutosDB = [
  {
    id: 1,
    title: "DOSSIÊ CONITEC E ANS",
    titleENG: "ANS AND CONITEC DOSSIERS",
    image: Produto1,
    endereco: "dossie-conitec-e-ans",
  },
  {
    id: 2,
    title: "DOSSIÊ CMED",
    titleENG: "CMED DOSSIER",
    image: Produto2,
    endereco: "dossie-cmed",
  },
  {
    id: 3,
    title: "PRE-ASSESSMENTS DE TECNOLOGIAS EM SAÚDE",
    titleENG: "PRE-ASSESSMENTS OF HEALTH TECHNOLOGIES",
    image: Produto3,
    endereco: "pre-assessments-de-tecnologias-em-saude",
  },
  {
    id: 4,
    title: "ESTUDO DE CUSTO-EFETIVIDADE",
    titleENG: "COST-EFFECTIVENESS STUDY",
    image: Produto4,
    endereco: "estudo-de-custo-efetividade",
  },
  {
    id: 5,
    title: "ANÁLISE DE IMPACTO ORÇAMENTÁRIO",
    titleENG: "BUDGET IMPACT ANALYSIS",
    image: Produto5,
    endereco: "analise-de-impacto-orcamentario",
  },
  {
    id: 6,
    title: "ESTUDOS DE REVISÃO SISTEMÁTICA",
    titleENG: "SYSTEMATIC REVIEW STUDIES",
    image: Produto6,
    endereco: "estudos-de-revisao-sistematica",
  },
  {
    id: 7,
    title: "METANÁLISES EM REDE",
    titleENG: "NETWORK META-ANALYSES",
    image: Produto7,
    endereco: "metanalises-em-rede",
  },
  {
    id: 8,
    title: "MODERAÇÃO DE PAINEL DE ESPECIALISTAS",
    titleENG: "EXPERT PANEL MODERATION",
    image: Produto8,
    endereco: "moderacao-de-painel-de-especialistas",
  },
  {
    id: 9,
    title: "ESCRITA CIENTÍFICA - ABSTRACT E ARTIGOS",
    titleENG: "SCIENTIFIC WRITING - ABSTRACT AND ARTICLES",
    image: Produto9,
    endereco: "escrita-cientifica-abstract-e-artigos",
  },
  {
    id: 10,
    title: "CALCULADORA DE CUSTOS DE TRATAMENTO",
    titleENG: "TREATMENT COST CALCULATOR",
    image: Produto10,
    endereco: "calculadora-de-custos-de-tratamento",
  },
  {
    id: 11,
    title: "ANÁLISE CRÍTICA PRÉ SUBMISSÃO",
    titleENG: "PRE-SUBMISSION CRITICAL ANALYSIS",
    image: Produto11,
    endereco: "analise-critica-pre-submissao",
  },
];

export const ProdutoImages = [
  Produto1,
  Produto2,
  Produto3,
  Produto4,
  Produto5,
  Produto6,
  Produto7,
  Produto8,
  Produto9,
  Produto10,
  Produto11,
];
