import React from "react";
import styles from "./Blog.module.css";
import { PostsDB } from "../../database/postsDb";

const Blog = () => {
  return (
    <main className={styles.mainBlog}>
      <div className={styles.blogDiv}>
        <section className={styles.postsSection}>
          <ul className={styles.postContent}>
            {PostsDB.map((elem) => (
              <li key={elem.id} id={styles.itemPostContent}>
                <h1 id={styles.postTitleContent}>{elem.title}</h1>
                {elem.paragraphs.map((parag) => (
                  <p id={styles.postContentParagraph}>{parag}</p>
                ))}
              </li>
            ))}
          </ul>
        </section>
        <section className={styles.postsSideBar}>
          <h2 id={styles.titleAntigos}>Postagens antigas:</h2>
          <ul className={styles.postContentSideBar}>
            {PostsDB.map((elem) => (
              <li key={elem.id} id={styles.itemPostContentSideBar}>
                <h1 id={styles.postTitleSideBar}>
                  {elem.title.slice(0, 20)}...
                </h1>
                <p id={styles.postSideBarParagraph}>
                  {elem.paragraphs[0].slice(0, 80)}...
                </p>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </main>
  );
};

export default Blog;
