import React from "react";
import styles from "./Button.module.css";

const Button = ({ text, icon, haveIcon = true, onClick, btnStyle }) => {
  return (
    <button
      className={btnStyle === "Dark" ? styles.buttonDark : styles.buttonLight}
      onClick={onClick}
    >
      <div id={styles.btnPos}>
        <span id={styles.btnText}>{text}</span>
        {haveIcon && <img src={icon} alt="Icone Saiba Mais" />}
      </div>
    </button>
  );
};

export default Button;
