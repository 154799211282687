import React, { useState, useEffect } from "react";
import styles from "./Clientes.module.css";
import { ClientesDB } from "../../database/clientes";
import Depoimentos from "../../components/Depoimentos/Depoimentos";
import { DepoimentosDB } from "../../database/depoimentos";

const Clientes = ({ page, lang }) => {
  const [depoimentoAtual, setDepoimentoAtual] = useState(DepoimentosDB[0]);

  const handleClickNextDepoimento = () => {
    if (depoimentoAtual.id === DepoimentosDB.length) {
      setDepoimentoAtual(DepoimentosDB[0]);
    } else {
      setDepoimentoAtual(DepoimentosDB[depoimentoAtual.id]);
    }
  };

  const handleClickPreviousDepoimento = () => {
    if (depoimentoAtual.id === 1) {
      setDepoimentoAtual(DepoimentosDB[DepoimentosDB.length - 1]);
    } else {
      setDepoimentoAtual(DepoimentosDB[depoimentoAtual.id - 2]);
    }
  };

  useEffect(() => {
    if (page === "Clientes") {
      const randomDepoimento = Math.floor(Math.random() * (DepoimentosDB.length - 1));
      setDepoimentoAtual(DepoimentosDB[randomDepoimento]);
    }
  }, [page])

  return (
    <main className={styles.clientesPage}>
      <section className={styles.descriptionSection}>
        <h1 id={styles.clientesTitle}>{lang === "BRA" ? "Nossos Clientes" : "Our Clients"}</h1>
      </section>
      <section className={styles.logosSection}>
        {ClientesDB.map((elem, index) => (
          <div key={index} className={styles.logoBox}>
            <img id={styles.logoImg} src={elem.image} alt={elem.company} />
          </div>
        ))}
      </section>
      <Depoimentos
        depoimento={depoimentoAtual}
        handleClickNextDepoimento={handleClickNextDepoimento}
        handleClickPreviousDepoimento={handleClickPreviousDepoimento}
        lang={lang}
      />
    </main>
  );
};

export default Clientes;
