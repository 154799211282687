import React from "react";
import styles from "./Produtos04.module.css";
import CoverProdutoComponent from "../../components/CoverProduto/CoverProduto";
import CoverProduto from "../../images/CoverProduto04.png";
// import Grafico1 from "../../images/grafico1.png";
// import Grafico2 from "../../images/grafico2.png";
// import Grafico3 from "../../images/grafico3.jpg";
import ButtonVoltar from "../../components/ButtonVoltar/ButtonVoltar";
import IconVoltar from "../../images/iconBtn1.svg";
import { useNavigate } from "react-router-dom";

const Produto04 = ({ lang }) => {
  const navigate = useNavigate();

  const handleOnClickProdutos = () => {
    return navigate("/produtos");
  };

  return (
    <main className={styles.mainProduto}>
      <CoverProdutoComponent
        image={CoverProduto}
        text={"Estudos de custo-efetividade"}
      />
      <section className={styles.sobreSection}>
        <h1 id={styles.sobreTitle}>{lang === "BRA" ? "Estudo de Custo-Efetividade" : "Cost-Effectiveness Study"}</h1>
        <div className={styles.sobreDiv}>
          {lang === "BRA" ? (
            <p id={styles.sobreDescription}>
              Conduzimos diversos tipos de estudos de custo-efetividade, incluindo
              custo-minimização e custo-utilidade, na perspectiva do sistema
              público ou privado de saúde. Utilizamos preferencialmente os
              softwares TreeAge ou Excel. Também realizamos adaptação de modelos
              globais para a realidade brasileira.
            </p>
          ) : (
            <p id={styles.sobreDescription}>
              We conduct different types of cost-effectiveness studies, including
              cost-minimization and cost-utility, from the perspective of the
              public or private healthcare systems. We preferably use TreeAge or
              Excel software. We also adapt global models to the Brazilian reality.
            </p>
          )}
        </div>
        <ButtonVoltar
          text={lang === "BRA" ? "VOLTAR PARA PRODUTOS" : "GO BACK TO PRODUCTS"}
          icon={IconVoltar}
          onClick={() => handleOnClickProdutos()}
          btnStyle={"Light"}
        />
      </section>
    </main>
  );
};

export default Produto04;
